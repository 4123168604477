<template>
  <main>
    <package-header title="Withdraw Application" sub-title="12764 Smith" packageName="Residential Transfer" status="Pending"></package-header>

    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-3">
          <router-link to="/pages/applications">
            <svg class="icon icon-arrow_cta_back mr-sm-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use></svg>
            <span class="d-none d-sm-inline-block">Back to Package</span>
            <span class="d-sm-none">Back</span>
          </router-link>

          <!-- Step wizard -->
          <div class="step-wizard my-3">
            <div class="step active">
              <a class="step-link" href="javascript:void(0)">Select Applications</a>
            </div>
            <div class="step">
              <a class="step-link" href="javascript:void(0)">Confirm Withdrawal</a>
            </div>
          </div>
        </div>

        <div class="col-lg-7">
          <h2 class="mb-3">Select Applications</h2>
          <div class="card mb-5">
            <div class="card-header bg-light">
              <span class="d-block text-uppercase">Step 1</span>
              <h3 class="mb-0">1 - Select application to withdraw</h3>
            </div>
            <div class="card-body p-0">
              <table class="table table-hover table-stacked table-select-row mb-0">
                <thead>
                  <tr>
                    <th scope="row">
                      <div class="custom-control custom-checkbox">
                        <input id="th" type="checkbox" class="custom-control-input" />
                        <label class="custom-control-label" for="th">&nbsp;</label>
                      </div>
                    </th>
                    <th>Application No.</th>
                    <th>Application Status</th>
                    <th scope="col">Name</th>
                    <th scope="col">Transaction Type</th>
                    <th scope="col">Document No</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in tableItems">
                    <td scope="row">
                      <div class="custom-control custom-checkbox">
                        <input :id="index" type="checkbox" class="custom-control-input" />
                        <label class="custom-control-label" :for="index">&nbsp;</label>
                      </div>
                    </td>
                    <td data-header="Application No.">{{ item.a }}</td>
                    <td data-header="Application Status">{{ item.b }}</td>
                    <td data-header="Name">{{ item.c }}</td>
                    <td data-header="Transaction Type">{{ item.d }}</td>
                    <td data-header="Document No">{{ item.d }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="card mb-4">
            <div class="card-header bg-light">
              <span class="d-block text-uppercase">Step 2</span>
              <h3 class="mb-0">1 - Select your authorization method</h3>
            </div>

            <div class="card-body">
              <div class="form-group mt-2">
                <div class="custom-control custom-radio">
                  <input type="radio" id="authorizationMethod1" name="authorizationMethod" class="custom-control-input" v-model="authorizationMethod" value="1" />
                  <label class="custom-control-label" for="authorizationMethod1">
                    <p>I am the applicant, or I am the Solicitor or Notary Public, or an employee of the Solicitor or Notary Public who is acting on behalf of the applicant(s) for the applications being withdrawn and I'm authorized to make this request.</p>

                    <div class="alert alert-info" role="alert">
                      <div class="alert-icon">
                        <svg class="icon icon-info"><use xlink:href="/icons/symbol-defs.svg#icon-info"></use></svg>
                      </div>
                      You have an unsubmitted withdrawal request. Complete and submit the request to ensure the withdrawal is processed.
                    </div>
                  </label>
                </div>
                <div class="custom-control custom-radio">
                  <input type="radio" id="authorizationMethod2" name="authorizationMethod" class="custom-control-input" v-model="authorizationMethod" value="2" />
                  <label class="custom-control-label" for="authorizationMethod2">I am an agent, who is acting on behalf of the applicant and I have an authorization letter to add to my withdraw request.</label>
                </div>
              </div>

              <div class="form-group mb-5 ml-4" v-if="authorizationMethod === '2'">
                <file-upload></file-upload>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-end mt-4">
            <button class="btn btn-primary">Confirm Withdrawel</button>
          </div>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Package Management"></help-panel>
  </main>
</template>

<script>
import PackageHeader from "../../components/PackageHeader.vue";
import PackageNavigation from "../../components/PackageNavigation.vue";
import HelpPanel from "../../components/HelpPanel.vue";
import FileUpload from "../../components/FileUpload.vue";

export default {
  components: {
    "package-header": PackageHeader,
    "package-navigation": PackageNavigation,
    "help-panel": HelpPanel,
    "file-upload": FileUpload,
  },

  data() {
    return {
      authorizationMethod: "",
      tableItems: [
        {
          a: "CA748827",
          b: "Pending",
          c: "Transfer Caso",
          d: "Freehold Transfer",
          e: "CA748827",
        },
        {
          a: "CA748828",
          b: "Pending",
          c: "A1 release",
          d: "Release",
          e: "CA748828",
        },
        {
          a: "CA748829",
          b: "Pending",
          c: "A1 release",
          d: "Release",
          e: "CA748828",
        },
        {
          a: "CA748830",
          b: "Pending",
          c: "A1 release",
          d: "Release",
          e: "CA748828",
        },
        {
          a: "CA748831",
          b: "Pending",
          c: "A1 release",
          d: "Release",
          e: "CA748828",
        },
        {
          a: "CA748832",
          b: "Pending",
          c: "Mortgage Caso",
          d: "Mortgage",
          e: "CA748832",
        },
      ],
    };
  },
  methods: {
    checkbox: function ($event) {
      var _this = this;

      if (event.target.checked) {
        _this.checked++;
      } else {
        _this.checked--;
      }
    },
  },
};
</script>
